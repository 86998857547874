import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../Asset/wlogo.png";
import HistoryPage from './HistoryTimeline';
import HistoryDes from './HistoryDes';
import Footer from './Footer';

export default function ClubHistory() {
  const [searchText, setSearchText] = useState("");

  return (
    <>
      <HistoryDes searchText={searchText} />
      <HistoryPage searchText={searchText} />
      <div id="footer-section">
        <Footer />
      </div>
    </>
  );
}
