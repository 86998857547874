import React, { useEffect, useState, useRef } from "react";
import './HistoryPage.css';
import { Calendar, Award, Zap, BookOpen, TrendingUp, Activity, Globe, FastForward } from "lucide-react";
import img2 from "../Gallery/img2.jpg"
import img4 from "../Gallery/img4.jpg"
import img5 from "../Gallery/img5.jpg"
import img6 from "../Gallery/img6.jpg"
import img8 from "../Gallery/img8.jpg"
import img9 from "../Gallery/img9.jpg"
import img12 from "../Gallery/img12.jpg"
import effi from "../Gallery/efficycle_team.jpg"
import teamPirates from "../Gallery/team_pirates.jpg"
import teamNh1 from "../Gallery/Team-NH1.jpg"

const HistoryPage = ({ searchText }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const navRef = useRef(null);

  // Check for mobile device
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener("resize", checkMobile);
    
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  // Scroll handling for timeline animation
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".timeline-item");
      
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const sectionId = section.getAttribute("data-id");
        
        // Show element when it's in viewport
        if (rect.top < window.innerHeight - 100) {
          section.classList.add("visible");
          
          // Set active section for navigation
          if (rect.top < window.innerHeight / 2 && 
              rect.bottom > window.innerHeight / 2) {
            setActiveSection(sectionId);
            
            // Scroll active button into view on mobile
            if (isMobile && navRef.current && sectionId) {
              const activeBtn = document.querySelector(`.timeline-nav button[data-section="${sectionId}"]`);
              if (activeBtn) {
                navRef.current.scrollLeft = activeBtn.offsetLeft - navRef.current.clientWidth / 2 + activeBtn.clientWidth / 2;
              }
            }
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile]);

  const getHighlightedText = (text, highlight) => {
    if (!highlight || !highlight.trim()) return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Timeline data structured for better organization
  const timelineData = [
    {
      id: "founding",
      title: "Founding and Early Years (2007–2010)",
      icon: <Calendar size={isMobile ? 16 : 20} />,
      content: [
        `SAE UIET was founded in 2007 by a team of enthusiastic engineers including Mr. Rana Singh Balgir, Mr. Rupak Banerjee, Mr. Harpreet Singh and their dynamic team. Initially, the chapter included students from Electrical, Electronics, and Mechanical branches.`,
        `In 2009, the first UIET Baja Team, "NH1," was established, marking the beginning of the university's participation in national-level Baja SAE events. Following this, in 2010, Team Pirates continued the tradition of creative design and engineering for Baja SAE.`
      ],
      images: [teamNh1]
    },
    {
      id: "efficycle",
      title: "The Efficycle Legacy Begins (2010–2013)",
      icon: <Zap size={isMobile ? 16 : 20} />,
      content: [
        `In 2010, SAE UIET introduced the concept of Efficycle to SAE India, hosting the first-ever Efficycle event at the university. This pioneering step established UIET as a central force in promoting sustainable engineering practices.`,
        `By 2013, Efficycle had expanded to include 129 teams from across India, solidifying UIET's position in the event's organization.`
      ],
      images: [teamPirates]
    },
    {
      id: "early-awards",
      title: "Early Awards and Achievements (2012–2016)",
      icon: <Award size={isMobile ? 16 : 20} />,
      content: [
        `SAE UIET's teams began achieving national recognition. In 2012, the Baja team "Chargeurs Phoenix" received the Best Cost Award, with notable performances in the 38-degree gradient test.`,
        `In 2013, SAE India launched the AWIM Program in collaboration with SAE UIET. Efficycle 2016 saw UIET's team rank highly with their lightweight tricycle design.`
      ],
      images: [img2]
    },
    {
      id: "expansion",
      title: "Expansion and New Ventures (2016–2019)",
      icon: <TrendingUp size={isMobile ? 16 : 20} />,
      content: [
        `Between 2016 and 2019, SAE UIET expanded its participation across various competitions. Teams Stormbreaker and Sabertooth represented UIET in Baja SAE, Go-Kart, and Formula SAE events.`,
        `In 2016, SAE UIET launched the EffiCars initiative. In 2019, a team from UIET earned first place at the Smart India Hackathon - Hardware Edition at IIT Hyderabad.`
      ],
      images: []
    },
    {
      id: "pandemic",
      title: "Adaptation During the Pandemic (2020–2021)",
      icon: <Activity size={isMobile ? 16 : 20} />,
      content: [
        `During COVID-19, SAE UIET shifted to virtual engagements. Team B-Ron Motorsports secured 1st position in the National Electric Kart Championship 2020.`,
        `SAE UIET organized online workshops on electric vehicles, hybrid technologies, and data analytics, ensuring continuous learning during the pandemic.`
      ],
      images: [img4]
    },
    {
      id: "resurgence",
      title: "Resurgence and Recognition (2022–2023)",
      icon: <Globe size={isMobile ? 16 : 20} />,
      content: [
        `With in-person activities resuming in 2022, SAE UIET renewed its commitment to innovation. The IC Go-Kart team advanced to the dynamic rounds with upgraded suspension and improved engine tuning.`,
        `In 2023, the team achieved All India Rank 2 at Aerothon 2023. In January 2024, SAE finalized its constitution, outlining the club's four-tier structure.`
      ],
      images: [img5]
    },
    {
      id: "legacy",
      title: "Legacy of 2023-2024: A Year of Excellence",
      icon: <BookOpen size={isMobile ? 16 : 20} />,
      content: [
        `The 2023-2024 tenure saw remarkable progress under Prof. Shankar Sehgal, Student Chairperson Akshit Kain, and Vice-Chair Parth Bansal.`,
        `Key achievements included participation in Technoxian 2024 World Robotics Championship, 3rd place in Robowar at Infotsav 2024, and hosting the PU Internal Hackathon for SIH 2024.`
      ],
      images: [img8]
    },
    {
      id: "year2025",
      title: "The Year 2025: Unleashing Innovation",
      icon: <FastForward size={isMobile ? 16 : 20} />,
      content: [
        `Team Abhedya participated in the Robosoccer tournament at IIT Jodhpur's Prometeo'25. Team Alpha One secured a spot among the top 13 teams in the Hackathon.`,
        `SAE Students attended the Bharat Mobility Global Expo 2025, exploring innovations in sustainable mobility technologies from leading global brands.`
      ],
      images: [img12]
    },
    {
      id: "future",
      title: "Looking Ahead",
      icon: <TrendingUp size={isMobile ? 16 : 20} />,
      content: [
        `SAE UIET PU remains committed to organizing impactful events, fostering technical innovation, and supporting members in excelling at future competitions.`
      ],
      images: []
    }
  ];

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      // Add offset for sticky header
      const yOffset = -70; 
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      
      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="history-container">
      <header className="history-header">
        <h1>Our Journey Through Time</h1>
        <p>
          Explore the history and achievements of SAE UIET Panjab University
        </p>
      </header>

      <nav className="timeline-nav" ref={navRef}>
        {timelineData.map((item) => (
          <button
            key={item.id}
            className={activeSection === item.id ? "active" : ""}
            data-section={item.id}
            onClick={() => scrollToSection(item.id)}
            aria-label={`Navigate to ${item.title}`}
          >
            <span className="nav-icon">{item.icon}</span>
            <span className="nav-label">{item.title.split("(")[0].trim()}</span>
          </button>
        ))}
      </nav>

      <section className="timeline">
        {timelineData.map((item, index) => (
          <article
            key={item.id}
            id={item.id}
            data-id={item.id}
            className="timeline-item"
          >
            <div className="timeline-content">
              <div className="timeline-header">
                <span className="timeline-icon">{item.icon}</span>
                <h2>{getHighlightedText(item.title, searchText)}</h2>
              </div>

              <div className="timeline-body">
                {item.content.map((paragraph, idx) => (
                  <p key={idx}>{getHighlightedText(paragraph, searchText)}</p>
                ))}
              </div>

              {item.images && item.images.length > 0 && (
                <div className="timeline-gallery">
                  {item.images
                    .slice(0, isMobile ? 1 : 2)
                    .map((img, imgIndex) => (
                      <div key={imgIndex} className="image-wrapper">
                        <img src={img} alt={`${item.title}`} loading="lazy" />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </article>
        ))}
      </section>
    </div>
  );
};

export default HistoryPage;