import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Share2, BookOpen, Calendar, ArrowRight } from "lucide-react";
import { useLocation } from "react-router-dom";
import ShareModal from "./ShareModal";

const ArticleSkeleton = () => {
  const location = useLocation();
  const [respData, setRespData] = useState([]);
  const [moreNewsletter, setMoreNewsletter] = useState([]);
  const [subscribeStatus, setSubscribeStatus] = useState("Subscribe");
  const [input, setInput] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareTitle, setShareTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  
  const subscribeRef = useRef(null);

  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("");

  useEffect(() => {
    const fetchContent = async () => {
      setIsLoading(true);
      try {
        const resp = await axios(
          `${process.env.REACT_APP_BASE_URL}/getData?title=${encodeURIComponent(title)}`,
          {
            headers: {
              "X-API-KEY": process.env.REACT_APP_AUTH_KEY,
            },
          }
        );
        setRespData(resp.data);
        setShareTitle(resp.data.title);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    fetchContent();
    onLoad();
  }, [title]);

  function removeObjectByTitle(array, titleToRemove) {
    return array.filter((item) => item.title !== titleToRemove).reverse();
  }

  const onLoad = async () => {
    try {
      const resp = await axios(`${process.env.REACT_APP_BASE_URL}/getAllData`, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_AUTH_KEY,
        },
      });
      const data = resp.data.data;
      const updatedData = removeObjectByTitle(data, title);
      setMoreNewsletter(updatedData.slice(0, 4));
    } catch (err) {
      console.log(err);
    }
  };

  function convertTagsToArray(tagsString) {
    if (!tagsString || typeof tagsString !== "string") {
      return [];
    }
    return tagsString.split(",").map((tag) => tag.trim());
  }

  async function onSubscribe() {
    if (!input || !input.includes('@')) {
      alert("Please enter a valid email address");
      return;
    }
    
    setSubscribeStatus("Subscribing...");
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addSub`,
        {
          submail: input,
        },
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_AUTH_KEY,
          },
        }
      );
      const data = resp.data.status_code;
      if (data === 200) {
        setSubscribeStatus("Subscribed ✓");
        setTimeout(() => setSubscribeStatus("Subscribe"), 3000);
      } else {
        alert("Not subscribed due to network issue! Try again!");
        setSubscribeStatus("Subscribe");
      }
    } catch (err) {
      console.log(err);
      setSubscribeStatus("Subscribe");
    }
  }

  function onClickArticle(article) {
    const parsedTitle = article.trim();
    window.open(`/techblog/?=${parsedTitle}`, "_blank");
  }

  const handleCloseShareModal = () => {
    setShowShareModal(false);
    document.body.style.overflow = "";
  };
  
  const handleOpenShareModal = (e) => {
    e.stopPropagation();
    setShowShareModal(true);
    document.body.style.overflow = "hidden";
  };

  const scrollToSubscribe = () => {
    subscribeRef.current.scrollIntoView({ 
      behavior: 'smooth',
      block: 'center'
    });
    setTimeout(() => {
      const inputElement = subscribeRef.current.querySelector('input');
      if (inputElement) {
        inputElement.focus();
      }
    }, 800);
  };

  const formattedDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <>
    <div className="min-h-screen bg-gray-950 text-white overflow-hidden relative z-0">
      <div className="fixed inset-0 bg-[linear-gradient(to_right,#1e293b_1px,transparent_1px),linear-gradient(to_bottom,#1e293b_1px,transparent_1px)] bg-[size:32px_32px] pointer-events-none z-[-1]">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-900/10 via-blue-950/10 to-black"></div>
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,rgba(29,78,216,0.05),transparent_70%)]"></div>
      </div>

      <nav className="border-b border-blue-900/20 backdrop-blur-sm bg-black/40 sticky top-0 z-50">
        <div className="max-w-6xl mx-auto px-4 py-4 flex justify-between items-center">
          <div className="text-xl font-bold bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent">
            Newsletter AI
          </div>
          <button
            onClick={scrollToSubscribe}
            className="bg-blue-600 hover:bg-blue-500 text-white px-4 py-1.5 rounded-full text-sm font-medium transition-all"
          >
            Subscribe Now
          </button>
        </div>
      </nav>

      <div className="max-w-4xl mx-auto px-6 py-12 relative z-10">

        <div className="flex items-center justify-center gap-4 mb-6 text-blue-400/70 text-sm">
          <div className="flex items-center gap-1">
            <Calendar className="w-4 h-4" />
            <span>{formattedDate}</span>
          </div>
          <div className="flex items-center gap-1">
            <BookOpen className="w-4 h-4" />
            <span>5 min read</span>
          </div>
        </div>

        <h1 className="text-4xl sm:text-5xl font-bold text-center mb-8 bg-gradient-to-r from-blue-100 via-blue-300 to-blue-100 bg-clip-text text-transparent leading-tight">
          {isLoading ? "Loading..." : respData.data?.title}
        </h1>

        <div className="flex flex-wrap justify-center gap-2 mb-8">
          {convertTagsToArray(respData.data?.tag || "").map((tag, index) => (
            <span
              key={index}
              className="bg-blue-900/20 text-blue-300 px-4 py-1.5 rounded-full text-sm border border-blue-500/30 backdrop-blur-sm hover:bg-blue-800/30 transition-colors"
            >
              #{tag}
            </span>
          ))}
        </div>

        <div className="flex items-center justify-center gap-3 mb-12">
          <div className="w-10 h-10 rounded-full bg-gradient-to-br from-blue-500 to-blue-700 flex items-center justify-center text-white font-bold">
            AI
          </div>
          <div className="flex flex-col">
            <span className="text-gray-200 font-medium">Newsletter AI</span>
            <span className="text-blue-400 text-xs">Tech Insights</span>
          </div>
          <div className="mx-2 h-8 border-r border-blue-500/20"></div>
          <button
            className="p-2 hover:bg-blue-900/30 rounded-full transition-colors group"
            onClick={handleOpenShareModal}
          >
            <Share2 className="w-5 h-5 text-blue-400 group-hover:text-blue-300" />
          </button>
        </div>

        {isLoading ? (
          <div className="space-y-6">
            {[1, 2, 3].map((i) => (
              <div key={i} className="animate-pulse">
                <div className="h-6 bg-blue-900/30 rounded mb-3 w-3/4"></div>
                <div className="h-4 bg-blue-900/20 rounded mb-2 w-full"></div>
                <div className="h-4 bg-blue-900/20 rounded mb-2 w-full"></div>
                <div className="h-4 bg-blue-900/20 rounded mb-2 w-5/6"></div>
              </div>
            ))}
          </div>
        ) : (
          <div className="prose prose-invert prose-lg max-w-none">
            {respData.data?.content?.length > 0 ? (
              respData.data?.content.map((section, index) => (
                <div key={index} className="mb-12 hover:bg-blue-900/5 p-4 rounded-lg transition-colors">
                  <h2 className="text-2xl font-semibold mb-4 text-blue-200 border-l-4 border-blue-500 pl-4">
                    {section.subtitle}
                  </h2>
                  <p className="text-gray-300 leading-relaxed">
                    {section.paragraph}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-400">
                No content available for this article.
              </p>
            )}
          </div>
        )}

        <div className="relative my-16">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-blue-900/30"></div>
          </div>
          <div className="relative flex justify-center">
            <span className="bg-gray-950 px-4 text-blue-400">More Articles</span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {moreNewsletter.length > 0 ? (
            moreNewsletter.map((item, index) => (
              <div
                key={index}
                onClick={() => onClickArticle(item.title)}
                className="bg-gradient-to-br from-blue-900/10 to-blue-950/5 backdrop-blur-sm border border-blue-500/20 rounded-xl p-6 hover:bg-blue-900/20 transition-all cursor-pointer group"
              >
                <h3 className="text-lg font-medium text-blue-200 group-hover:text-blue-100 mb-2">{item.title}</h3>
                <div className="flex items-center justify-between mt-4">
                  <span className="text-sm text-gray-400">Read article</span>
                  <ArrowRight className="w-4 h-4 text-blue-400 group-hover:translate-x-1 transition-transform" />
                </div>
              </div>
            ))
          ) : (
            <div className="col-span-2 text-center text-gray-400 py-8">
              Loading more articles...
            </div>
          )}
        </div>

        <div ref={subscribeRef} className="mt-16 bg-gradient-to-br from-blue-900/20 to-blue-950/10 backdrop-blur-sm border border-blue-500/20 rounded-xl p-8">
          <h2 className="text-2xl font-bold mb-3 text-center text-blue-100">Stay Updated</h2>
          <p className="text-center text-gray-400 mb-6">Get the latest tech insights delivered to your inbox</p>
          <div className="max-w-md mx-auto">
            <div className="flex flex-col sm:flex-row items-center justify-center gap-3">
              <input
                type="email"
                placeholder="Enter your email"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="w-full flex-1 bg-black/30 border border-blue-500/30 rounded-lg px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-transparent"
              />
              <button
                onClick={onSubscribe}
                className="w-full sm:w-auto bg-gradient-to-r from-blue-600 to-blue-500 text-white px-6 py-3 rounded-lg font-semibold hover:from-blue-500 hover:to-blue-400 transition-all active:scale-95"
              >
                {subscribeStatus}
              </button>
            </div>
          </div>
        </div>

        <footer className="mt-16 border-t border-blue-900/20 pt-8 text-center text-gray-500 text-sm">
          <p>© {new Date().getFullYear()} Newsletter AI. All rights reserved.</p>
        </footer>
      </div>
    </div>
    <ShareModal title={shareTitle} isOpen={showShareModal} onClose={handleCloseShareModal}/>
    </>
  );
};

export default ArticleSkeleton;