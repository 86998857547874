import React from 'react';
import './HistoryDes.css';
import saeLogoImage from '../Asset/saebf.png';
import { NavLink } from 'react-router-dom';
import { Info, Calendar, Users, Award, MapPin, Building, User, UserCheck, Link } from 'lucide-react';

export default function HistoryDes({ searchText }) {
  // Function to highlight searched text remains the same
  const getHighlightedText = (text, highlight) => {
    if (!highlight?.trim()) return text;
    
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Team data for better organization
  const teams = [
    {
      id: "garuda",
      name: "Team Garuda",
      description: "The Motorsports Team of SAE: This team specializes in designing and building Formula-style cars, ATVs, and Go-Karts for student competitions while exploring innovative sustainable mobility technologies such as Hyperloop and Hydrogen-CNG-powered vehicles."
    },
    {
      id: "abhedya",
      name: "Team Abhedya",
      description: "The Robotics Team of SAE: The most versatile domain in the club, Abhedya participates in national and international competitions hosted by IITs, NITs, and other prestigious institutions. The team competes in events like Maze Solver, Robowars, RC Racing, and Robo Soccer while also developing research-focused projects, including robotic arms and self-balancing bots."
    },
    {
      id: "vayu",
      name: "Team Vayuveer",
      description: "The Aerospace Team of SAE: This domain focuses on cutting-edge aerospace technologies, working on projects like RC planes, rescue drones, anti-drone systems, and water rockets, contributing to innovations in the field."
    },
    {
      id: "alpha",
      name: "Team Alpha One",
      description: "The Software Team of SAE: Dedicated to software innovation, Alpha One engages in website development, database systems, and AI-driven projects. The team regularly participates in hackathons, achieving consistent victories and adding to the club's success in software development."
    }
  ];

  // Organization info data for better organization
  const orgInfo = [
    { icon: <Info />, label: "Abbreviation", value: "SAE UIET PU" },
    { icon: <Calendar />, label: "Formation", value: "2007" },
    { icon: <Building />, label: "Type", value: "Non - Profit Organisation" },
    { icon: <Award />, label: "Legal Status", value: "Active" },
    { icon: <MapPin />, label: "Headquarters", value: "Chandigarh, India" },
    { icon: <Users />, label: "Members", value: "150" },
    { icon: <User />, label: "Chairperson", value: "Akshit Kain" },
    { icon: <UserCheck />, label: "Vice - Chairperson", value: "Parth Bansal" },
    { icon: <Building />, label: "Parent Organization", value: "SAE India" },
    { icon: <Link />, label: "Website", value: "saeuietpu.in", isLink: true }
  ];

  return (
    <div className="home-container">
      {/* Hero Section with Background Graph Pattern */}
      <div className="hero-section">
        <div className="graph-background"></div>
        <h1 className="title">
          {getHighlightedText(`SAE UIET Panjab University`, searchText)}
        </h1>
      </div>

      <div className="content-container">
        {/* Club Description & Teams Section */}
        <div className="teams-section">
          <div className="overview">
            <p className="lead-text">
              {getHighlightedText(
                `The SAE UIET Panjab University is a collegiate club affiliated with SAE (Society of Automotive Engineers) India
                and operates within the University Institute of Engineering and Technology (UIET), Panjab University. 
                The club functions across four dynamic domains that fuel its diverse projects and accomplishments.`,
                searchText
              )}
            </p>
          </div>

          <div className="teams-container">
            {teams.map((team, index) => (
              <div key={team.id} className="team-card" id={team.id}>
                <h3 className="team-name">{getHighlightedText(team.name, searchText)}</h3>
                <p className="team-description">
                  {getHighlightedText(team.description, searchText)}
                </p>
              </div>
            ))}
          </div>

          <p className="conclusion-text">
            {getHighlightedText(
              `Together, these four domains exemplify SAE UIET's commitment to technological advancement and excellence.`,
              searchText
            )}
          </p>
        </div>

        {/* Organization Info Card */}
        <div className="org-info-card">
          <div className="org-header">
            <h2 className="org-title">
              {getHighlightedText('Society of Automotive Engineers, UIET, Panjab University', searchText)}
            </h2>
            <div className="logo-container">
              <img src={saeLogoImage} alt="SAE UIET Logo" className="org-logo" />
            </div>
          </div>

          <div className="info-grid">
            {orgInfo.map((item, index) => (
              <div key={index} className="info-item">
                <div className="info-icon">{item.icon}</div>
                <div className="info-content">
                  <p className="info-label">{getHighlightedText(item.label, searchText)}</p>
                  {item.isLink ? (
                    <NavLink to="https://saeuietpu.in/" className="info-link">
                      {getHighlightedText(item.value, searchText)}
                    </NavLink>
                  ) : (
                    <p className="info-value">{getHighlightedText(item.value, searchText)}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}